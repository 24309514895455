import axiosInstance, { makeCancelableRequest } from '@app/utils/axiosInstance';
import type { SharedSecurityDetailsResponse } from '@bsa/shared-types';
import axios, { AxiosRequestConfig } from 'axios';

export async function searchSecuritiesService(
  searchTerm: string,
  setErrorMessage?: (message: string) => void,
): Promise<SharedSecurityDetailsResponse[]> {
  const config: AxiosRequestConfig = {
    method: 'get',
    url: `/securities?searchTerm=*${searchTerm}*`,
  };

  try {
    return await makeCancelableRequest<SharedSecurityDetailsResponse[]>(
      'searchSecurities',
      config,
    );
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error('Request was cancelled');
    }
    if (setErrorMessage) {
      setErrorMessage('Failed to fetch securities');
    }
    throw new Error('Failed to fetch securities');
  }
}

/**
 * Get the selected security market price
 * @param securityId security id to search for
 * @param setErrorMessage set error message
 * @returns security details response
 */
export async function getSelectedSecurityMarketPriceService(
  securityId: number,
  setErrorMessage?: (message: string) => void,
): Promise<Partial<SharedSecurityDetailsResponse>> {
  try {
    const response = await axiosInstance.get(`/securities/${securityId}`);
    return response.data;
  } catch {
    if (setErrorMessage) {
      setErrorMessage(
        'Failed to fetch market price & user currency for security',
      );
    }
    throw new Error(
      'Failed to fetch market price & user currency for security',
    );
  }
}
