import { IressPanel, IressStack, IressText } from '@iress/components-react';
import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { RootState, AppDispatch } from '@app/app/store';
import { Spinner } from '@app/components/Spinner';
import { AgGrid } from '@app/components/AgGrid';
import {
  ColDef,
  IRowNode,
  SelectionChangedEvent,
  FirstDataRenderedEvent,
  GetRowIdParams,
  FilterChangedEvent,
  SortDirection,
} from 'ag-grid-community';
import type {
  AccountGroupLevelResponse,
  AccountLevelResponse,
} from '@bsa/shared-types';
import { RefObject, useCallback, useEffect, useMemo } from 'react';
import {
  AccountGroupsState,
  setSelectedPositions,
} from '@app/features/AccountGroups';
import { SharedViewLevelParamExtended } from '@app/types/sharedViewLevelParamExtended';
import '@app/app/index.css';
import { AgGridReact } from 'ag-grid-react';

export type Positions = AccountGroupLevelResponse | AccountLevelResponse;

const numberFilter = { filter: 'agNumberColumnFilter' };
const columnType = {
  symbolValueColumn: {
    ...numberFilter,
    type: ['numericColumn', 'symbolValueColumn'],
    autoHeight: true,
  },
  percentageColumn: {
    ...numberFilter,
    type: ['numericColumn', 'percentageColumn'],
    autoHeight: true,
  },
  volumeColumn: {
    ...numberFilter,
    type: ['numericColumn', 'volumeColumn'],
    autoHeight: true,
  },
};

const generateCommonColumnDefs = (excludedFields: string[] = []) => {
  const baseCommonDefs = [
    {
      field: 'value',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'cashAmount',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'cashPercentage',
      ...columnType.percentageColumn,
    },
    {
      field: 'units',
      ...columnType.volumeColumn,
    },
    {
      field: 'costBase',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'holdingValue',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'gainLoss',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'taxableGainLoss',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'weight',
      ...columnType.percentageColumn,
    },
  ];

  return baseCommonDefs.filter((col) => !excludedFields.includes(col.field));
};

export const usePositionsGrid = (
  viewLevel: SharedViewLevelParamExtended,
  dispatch: AppDispatch,
  gridRef: RefObject<AgGridReact>,
  accountGroupItems: AccountGroupsState['accountGroupItems'],
  includeZeroHoldings: AccountGroupsState['includeZeroHoldings'],
) => {
  const columnDefs = useMemo((): ColDef[] => {
    const accountPositionsColumnDefs = [
      { field: 'selected', hide: true },
      {
        field: 'account',
        autoHeight: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { field: 'accountGroupCode', autoHeight: true },
      {
        field: 'accountGroupName',
        autoHeight: true,
        sort: 'asc' as SortDirection,
      },
      { field: 'externalAccountId', autoHeight: true },
      { field: 'portfolio', autoHeight: true },
      { field: 'serviceType', autoHeight: true },
      { field: 'targetSet', autoHeight: true },
      { field: 'taxType', autoHeight: true },
      {
        field: 'accountId',
        filter: 'agNumberColumnFilter',
        type: ['numericColumn'],
        hide: true,
      },
      ...generateCommonColumnDefs(),
    ];

    const groupPositionsColumnDefs = [
      { field: 'selected', hide: true },
      {
        field: 'accountGroupCode',
        autoHeight: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        field: 'accountGroupName',
        autoHeight: true,
        sort: 'asc' as SortDirection,
      },
      { field: 'portfolio', autoHeight: true },
      { field: 'allocationTarget', autoHeight: true },
      { field: 'serviceType', autoHeight: true },
      { field: 'targetSet', autoHeight: true },
      { field: 'planType', autoHeight: true },
      { field: 'investmentAdvisor', autoHeight: true },
      { field: 'riskProfile', autoHeight: true },
      ...generateCommonColumnDefs(['taxableGainLoss', 'gainLoss']),
    ];

    return viewLevel === 'accountGroups'
      ? groupPositionsColumnDefs
      : accountPositionsColumnDefs;
  }, [viewLevel]);

  const handleOnSelectedAndFilteredEvent = useCallback(
    (event: SelectionChangedEvent | FilterChangedEvent) => {
      const filterAndSelectedRows: Positions[] = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          filterAndSelectedRows.push(node.data);
        }
      });
      dispatch(setSelectedPositions(filterAndSelectedRows));
    },
    [dispatch],
  );

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent<Positions>) => {
      const nodesToSelect: IRowNode[] = [];
      params.api.forEachNode((node) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (node.data?.selected) {
          nodesToSelect.push(node);
        }
      });
      params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
    },
    [],
  );

  const getAccountLevelRowId = useCallback(
    (params: GetRowIdParams<AccountLevelResponse>) => {
      return params.data.accountId.toString();
    },
    [],
  );

  const getAccountGroupLevelRowId = useCallback(
    (params: GetRowIdParams<AccountGroupLevelResponse>) => {
      return params.data.accountGroupCode;
    },
    [],
  );

  const updateSelectedRows = useCallback(() => {
    if (gridRef.current && includeZeroHoldings) {
      const oldRows = accountGroupItems.data.withoutZeroHoldings;
      const nodesToSelect: IRowNode[] = [];
      if (viewLevel === 'accountGroups') {
        gridRef.current.api?.forEachNode((node) => {
          if (
            !oldRows.some(
              (row) =>
                (row as AccountGroupLevelResponse).accountGroupCode ===
                (node.data as AccountGroupLevelResponse).accountGroupCode,
            )
          ) {
            nodesToSelect.push(node);
          }
        });
      } else {
        gridRef.current.api?.forEachNode((node) => {
          if (
            !oldRows.some(
              (row) =>
                (row as AccountLevelResponse).accountId ===
                (node.data as AccountLevelResponse).accountId,
            )
          ) {
            nodesToSelect.push(node);
          }
        });
      }

      gridRef.current.api?.setNodesSelected({
        nodes: nodesToSelect,
        newValue: true,
      });
    }
  }, [gridRef, accountGroupItems, viewLevel, includeZeroHoldings]);

  return {
    columnDefs,
    handleOnSelectedAndFilteredEvent,
    onFirstDataRendered,
    getAccountGroupLevelRowId,
    getAccountLevelRowId,
    updateSelectedRows,
  };
};

interface Props {
  gridRef: RefObject<AgGridReact>;
}

function PositionsGrid({ gridRef }: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const accountGroupItems = useAppSelector(
    (state: RootState) => state.accountGroups.accountGroupItems,
  );
  const viewData = useAppSelector(
    (state: RootState) => state.accountGroups.viewData,
  );
  const viewLevel = useAppSelector(
    (state: RootState) => state.accountGroups.viewLevel,
  );
  const includeZeroHoldings = useAppSelector(
    (state: RootState) => state.accountGroups.includeZeroHoldings,
  );

  const data = accountGroupItems.data[viewData];
  const { loading, error } = accountGroupItems;

  const {
    columnDefs,
    handleOnSelectedAndFilteredEvent,
    onFirstDataRendered,
    getAccountGroupLevelRowId,
    getAccountLevelRowId,
    updateSelectedRows,
  } = usePositionsGrid(
    viewLevel,
    dispatch,
    gridRef,
    accountGroupItems,
    includeZeroHoldings,
  );

  useEffect(() => {
    updateSelectedRows();
  }, [includeZeroHoldings, updateSelectedRows]);

  return (
    <IressPanel
      padding={IressPanel.Padding.Sm}
      data-testid="account-groups"
      className="grid-container"
    >
      {data.length > 0 && loading === 'succeeded' ? (
        <>
          <AgGrid<Positions>
            gridRef={gridRef}
            rowData={data}
            columnDefs={columnDefs}
            onSelectionChanged={handleOnSelectedAndFilteredEvent}
            onFirstDataRendered={onFirstDataRendered}
            onFilterChanged={handleOnSelectedAndFilteredEvent}
            getRowId={
              viewLevel === 'accountGroups'
                ? getAccountGroupLevelRowId
                : getAccountLevelRowId
            }
          />
        </>
      ) : (
        <IressStack gutter={IressStack.Gutter.Md}>
          <IressText align={IressText.Align.Center} data-testid="no-rows-text">
            No rows to show. The filtered results will be displayed here.
          </IressText>
          <IressText align={IressText.Align.Center}>
            {loading === 'pending' && <Spinner />}
            {loading === 'failed' && error}
          </IressText>
        </IressStack>
      )}
    </IressPanel>
  );
}

export default PositionsGrid;
