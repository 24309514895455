import axiosInstance from '@app/utils/axiosInstance';
import {
  AccountGroupAdjustment,
  Adjustment,
  SharedAccountGroupManualAdjustment,
  SharedAccountManualAdjustment,
  SharedManualAdjustmentType,
} from '@bsa/shared-types';

export const failedToApplyManualAdjustmentsMessage =
  'Failed to apply manual adjustments';

export async function manualAdjustments(
  type: SharedManualAdjustmentType,
  value: string,
  proposal: Adjustment,
  setErrorMessage?: (message: string) => void,
): Promise<Adjustment> {
  const body: SharedAccountManualAdjustment = {
    type,
    value,
    proposal,
  };

  try {
    const response = await axiosInstance.post(
      '/manual-adjustments/account/adjust',
      body,
    );
    return response.data;
  } catch {
    if (setErrorMessage) {
      setErrorMessage(failedToApplyManualAdjustmentsMessage);
    }
    throw new Error(failedToApplyManualAdjustmentsMessage);
  }
}

export async function accountGroupsManualAdjustments(
  type: SharedManualAdjustmentType,
  value: string,
  proposal: AccountGroupAdjustment,
  setErrorMessage?: (message: string) => void,
): Promise<AccountGroupAdjustment> {
  const body: SharedAccountGroupManualAdjustment = {
    type,
    value,
    proposal,
  };

  try {
    const response = await axiosInstance.post(
      '/manual-adjustments/accountgroup/adjust',
      body,
    );
    return response.data;
  } catch {
    if (setErrorMessage) {
      setErrorMessage(failedToApplyManualAdjustmentsMessage);
    }
    throw new Error(failedToApplyManualAdjustmentsMessage);
  }
}
