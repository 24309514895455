import { SharedProposalResponse } from "@bsa/shared-types";

export const mockProposalsResponse: SharedProposalResponse = {
    proposals: [
        {        
        accountid: 1,
        },
        {
        accountid: 2,        
        }
    ],
    failedProposals: [
        {
        accountid: 3,
        },
        {
        accountid: 4,
        }
    ]
  };