import { SharedSysadminFormattingResponse } from '@bsa/shared-types';
/* 
Tip:  When you update the sysadmin service mocks in the bff
      just copy the expected values from bff src/__mocks__/sysadmin.mocks.ts file
      They should have the exact value and structure
*/

export const mockFormattingResponse: SharedSysadminFormattingResponse = {
  numeric: {
    general: {
      dp: 2,
      maxdp: -1,
      negpre: '',
      negpost: '',
      pospre: '',
      pospost: '',
      comma: true,
    },
    reporting: {
      dp: 2,
      maxdp: -1,
      negpre: '',
      negpost: '',
      pospre: '',
      pospost: '',
      comma: true,
    },
    portfolio: {
      dp: 2,
      maxdp: -1,
      negpre: '',
      negpost: '',
      pospre: '',
      pospost: '',
      comma: true,
    },
  },
  money: {
    general: {
      dp: 2,
      maxdp: -1,
      negpre: '£(',
      negpost: ')',
      pospre: '£',
      pospost: '',
      comma: true,
    },
    reporting: {
      dp: 2,
      maxdp: -1,
      negpre: '£(',
      negpost: ')',
      pospre: '£',
      pospost: '',
      comma: true,
    },
    portfolio: {
      dp: 2,
      maxdp: -1,
      negpre: '£(',
      negpost: ')',
      pospre: '£',
      pospost: '',
      comma: true,
    },
  },
  percent: {
    general: {
      dp: 2,
      maxdp: -1,
      negpre: '-',
      negpost: '%',
      pospre: '',
      pospost: '%',
      comma: true,
    },
    reporting: {
      dp: 2,
      maxdp: -1,
      negpre: '-',
      negpost: '%',
      pospre: '',
      pospost: '%',
      comma: true,
    },
    portfolio: {
      dp: 2,
      maxdp: -1,
      negpre: '-',
      negpost: '%',
      pospre: '',
      pospost: '%',
      comma: true,
    },
  },
  'portfolio:market_value': {
    general: {
      dp: 2,
      maxdp: -1,
      negpre: '£(',
      negpost: ')',
      pospre: '£',
      pospost: '',
      comma: true,
    },
    reporting: {
      dp: 2,
      maxdp: -1,
      negpre: '£(',
      negpost: ')',
      pospre: '£',
      pospost: '',
      comma: true,
    },
    portfolio: {
      dp: 2,
      maxdp: -1,
      negpre: '£(',
      negpost: ')',
      pospre: '£',
      pospost: '',
      comma: true,
    },
  },
  'portfolio:unit_price': {
    general: {
      dp: 2,
      maxdp: -1,
      negpre: '£(',
      negpost: ')',
      pospre: '£',
      pospost: '',
      comma: true,
    },
    reporting: {
      dp: 2,
      maxdp: -1,
      negpre: '£(',
      negpost: ')',
      pospre: '£',
      pospost: '',
      comma: true,
    },
    portfolio: {
      dp: 2,
      maxdp: -1,
      negpre: '£(',
      negpost: ')',
      pospre: '£',
      pospost: '',
      comma: true,
    },
  },
  'portfolio:volume': {
    general: {
      dp: 4,
      maxdp: -1,
      negpre: '-',
      negpost: '',
      pospre: '',
      pospost: '',
      comma: true,
    },
    reporting: {
      dp: 4,
      maxdp: 6,
      negpre: '-',
      negpost: '',
      pospre: '',
      pospost: '',
      comma: true,
    },
    portfolio: {
      dp: 2,
      maxdp: -1,
      negpre: '-',
      negpost: '',
      pospre: '',
      pospost: '',
      comma: true,
    },
  },
  'portfolio:volume_fund': {
    general: {
      dp: 4,
      maxdp: -1,
      negpre: '-',
      negpost: '',
      pospre: '',
      pospost: '',
      comma: true,
    },
    reporting: {
      dp: 4,
      maxdp: -1,
      negpre: '-',
      negpost: '',
      pospre: '',
      pospost: '',
      comma: true,
    },
    portfolio: {
      dp: -1,
      maxdp: 10,
      negpre: '-',
      negpost: '',
      pospre: '',
      pospost: '',
      comma: true,
    },
  },
};
