import { InMemoryStorageProvider, UnleashClient } from 'unleash-proxy-client';

// Add toggles here.
export const UNLEASH_TOGGLES = {
  AccountGroupBuyAndSellToPercent: 'bsa.accountgroup.buy_and_sell_to_percent',
};

const UNLEASH_PROXY_URL = {
  technet:
    'https://public-api.feature-toggle.aws-platform-dev-au.iress.online/proxy/',
  staging:
    'https://public-api.feature-toggle.aws-platform-staging-au.iress.online/proxy/',
  production:
    'https://public-api.feature-toggle.aws-platform-production-au.iress.online/proxy/',
};

// Use convention to determine which env we're running in
const determineProxyUrl = (): string => {
  const baseUrl = window.location.hostname;
  if (baseUrl.includes('staging') || baseUrl === 'localhost') {
    return UNLEASH_PROXY_URL.staging;
  }
  return UNLEASH_PROXY_URL.production;
};

export const configureUnleash = (): UnleashClient => {
  const CLIENT_KEY = 'xplan-sparkle';
  const FIVE_MINUTES = 300;

  const unleashInstance = new UnleashClient({
    // Using InMemoryStorageProvider to avoid conflicts with Apex unleash calls in XPlan
    storageProvider: new InMemoryStorageProvider(),
    url: determineProxyUrl(),
    refreshInterval: FIVE_MINUTES,
    clientKey: CLIENT_KEY,
    appName: CLIENT_KEY,
  });

  unleashInstance.setContextField('siteID', window.location.hostname);
  return unleashInstance;
};
