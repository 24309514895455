import type { SharedSecurityDetailsResponse } from '@bsa/shared-types';
import { MenuItemMeta } from '@iress/components-react';

export function setLabel(security: SharedSecurityDetailsResponse) {
  if (security.searchType === 'ISIN') {
    return `${security.isin} - ${security.description}`;
  }
  if (security.searchType === 'SEDOL') {
    return `${security.sedol} - ${security.description}`;
  }
  return `${security.code}.${security.exchange} - ${security.description}`;
}

export function modifySecuritiesToMenuItems(
  securities: SharedSecurityDetailsResponse[],
): MenuItemMeta[] {
  if (!securities?.[0]?.securityId) {
    return [];
  }
  return securities.map((security) => ({
    value: security.securityId.toString(),
    label: setLabel(security),
    meta: security.searchType,
  }));
}
