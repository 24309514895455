import { useAppSelector } from '@app/app/hooks';
import { RootState } from '@app/app/store';
import { generalNumericFormatter } from '@app/utils/formatters';
import { IressExpander, IressTable, IressText } from '@iress/components-react';
import { useMemo } from 'react';

function SecurityDetails() {
  const selectedSecurity = useAppSelector(
    (state: RootState) => state.securities.selectedSecurity,
  );
  const fetchingDetailsStatus =
    useAppSelector((state: RootState) => state.securities.status) ===
    'succeeded';
  const formattingSetting = useAppSelector(
    (state: RootState) => state.sysadmin.settings,
  );
  const rowData = useMemo(() => {
    return (
      selectedSecurity && [
        {
          Security: `${selectedSecurity.code}.${selectedSecurity.exchange}`,
          Instrument: selectedSecurity.description,
          Sedol: selectedSecurity.sedol,
          ISIN: selectedSecurity.isin,
          'Native currency': selectedSecurity.nativeCurrency,
          'Native price': generalNumericFormatter(formattingSetting)(
            Number(selectedSecurity.nativePrice),
          ),
          'User currency': selectedSecurity.userCurrency,
          'Market price': generalNumericFormatter(formattingSetting)(
            Number(selectedSecurity.marketPrice),
          ),
          'Asset class': selectedSecurity.assetClass,
        },
      ]
    );
  }, [selectedSecurity, formattingSetting]);

  return (
    <div>
      {selectedSecurity && fetchingDetailsStatus && (
        <IressExpander
          mode={IressExpander.Mode.Link}
          data-testid="security-details-expander"
        >
          <IressText slot={IressExpander.Slot.Activator}>
            View security details
          </IressText>

          <IressTable
            rowData={rowData}
            hiddenCaption
            caption="Security Details"
          />
        </IressExpander>
      )}
    </div>
  );
}
export default SecurityDetails;
