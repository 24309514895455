import React from 'react';
import ReactDOM from 'react-dom/client';
import { worker } from '@app/__mocks__/mock-worker';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { setupStore } from './store';
import { router } from './router';
import { FlagProvider } from '@unleash/proxy-client-react';
import { configureUnleash } from './unleash';

if (import.meta.env.VITE_MOCK_BFF_RESPONSE) {
  await worker.start();
}

ReactDOM.createRoot(
  document.querySelector('#bulk-security-actions-root')!,
).render(
  <React.StrictMode>
    <FlagProvider unleashClient={configureUnleash()}>
      <Provider store={setupStore()}>
        <link
          rel="stylesheet"
          href="https://cdn.iress.com/icons/5.15.4/css/combined.min.css"
          integrity="sha384-O0tKAkjAKb3hzzRO6Q+3v+zLDboFGyCPSPFzMbNdv4weAM/UF5hCJua1gLIjRbXP"
          crossOrigin="anonymous"
        />
        <RouterProvider router={router} />
      </Provider>
    </FlagProvider>
  </React.StrictMode>,
);
