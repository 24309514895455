import { createSlice } from '@reduxjs/toolkit';
import { SharedSysadminFormattingResponse } from '@bsa/shared-types';
import { fetchFormattingSettings } from './sysadminThunk';

interface SysadminState {
  settings: SharedSysadminFormattingResponse;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  message: string;
}

export const initialSysadminState: SysadminState = {
  settings: {},
  loading: 'idle',
  message: '',
};

export const SysadminSlice = createSlice({
  name: 'Sysadmin',
  initialState: initialSysadminState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormattingSettings.fulfilled, (state, action) => {
        state.settings = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(fetchFormattingSettings.rejected, (state) => {
        state.loading = 'failed';
        state.message = 'Failed to fetch formatting settings';
      })
      .addCase(fetchFormattingSettings.pending, (state) => {
        state.loading = 'pending';
        state.message = 'Loading formatting settings';
      });
  },
});

export default SysadminSlice.reducer;
