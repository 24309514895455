import { useAppSelector } from '@app/app/hooks';
import { IressField, IressInput } from '@iress/components-react';
import { RootState } from '@app/app/store';

function InputUnitPrice() {
  const userCurrency = useAppSelector(
    (state: RootState) => state.securities.selectedSecurity.userCurrency,
  );

  const unitPrice = useAppSelector(
    (state: RootState) => state.securities.selectedSecurity.marketPrice,
  );

  return (
    <IressField
      label="Unit price"
      key="unit-price"
      data-testid="unit-price-label"
    >
      <IressInput
        type={IressInput.Type.Text}
        data-testid="unit-price-input"
        width={IressInput.Width.Four}
        required
        readonly={true}
        value={unitPrice}
      >
        <span slot={IressInput.Slot.Append}>{userCurrency}</span>
      </IressInput>
    </IressField>
  );
}
export default InputUnitPrice;
