import { useCallback, useEffect } from 'react';
import { setDataItems } from '@app/features/AccountGroups';
import { fetchFormattingSettings } from '@app/features/Sysadmin';
import { Outlet } from 'react-router-dom';
import { IressPanel } from '@iress/components-react';
import { useAppDispatch } from './hooks';

import '@iress/themes/build/css/iress-theme-light.css';
import '@iress/themes/build/css/iress-beta-theme-light.css';
import '@iress/themes/build/css/iress-beta-theme-dark.css';
import '@iress/themes/build/css/iress-theme-corporate.css';
import '@iress/themes/build/css/hostplus-theme-light.css';
import '@iress/themes/build/css/iress-theme-dark.css';
import '@iress/themes/global.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '@iress/ag-grid-theme/dist/lite/css/all.css';

function App() {
  const dispatch = useAppDispatch();

  const checkData = useCallback(() => {
    if (window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA?.data) {
      dispatch(setDataItems(window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA.data));
      delete window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA;
    }
  }, [dispatch]);

  useEffect(() => {
    if (window.location.hash.includes('#thehub/bulk_security_actions')) {
      checkData();
    }
  }, [checkData]);

  useEffect(() => {
    void dispatch(fetchFormattingSettings());
  }, [dispatch]);
  return (
    <IressPanel
      className="iress-beta-theme-light iress-view-mode-standard"
      padding={IressPanel.Padding.Md}
      background={IressPanel.Background.Page}
      data-testid="app"
      style={{ height: 'calc(100vh - 100px)' }}
    >
      <Outlet />
    </IressPanel>
  );
}

export default App;
