import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { AgGrid } from '@app/components/AgGrid';
import {
  AccountGroupAdjustment,
  AccountGroupLevelResponse,
  SharedSecurityDetailsResponse,
  type AccountLevelResponse,
  type Adjustment,
} from '@bsa/shared-types';

import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { AppDispatch, RootState } from '@app/app/store';
import { AgGridReact } from 'ag-grid-react';
import { IressPanel } from '@iress/components-react';
import '@app/app/index.css';
import { handleManualAdjustment } from './ProposalsGrid.handlers';
import {
  CellClassParams,
  CellEditRequestEvent,
  CellValueChangedEvent,
  ColDef,
  EditableCallbackParams,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetRowIdParams,
  IGroupCellRendererParams,
  SelectionChangedEvent,
  SortDirection,
} from 'ag-grid-community';
import {
  setOriginalProposals,
  setSelectedAdjustments,
} from '@app/features/AccountGroups';
import { SharedViewLevelParamExtended } from '@app/types/sharedViewLevelParamExtended';
import { camelCaseToTitle } from '../AgGrid/getters';
import { calculateTargetNativeValue } from './ProposalsGrid.utils';

export type Proposals = AccountGroupLevelResponse | AccountLevelResponse;
export type Proposal = Adjustment;

interface Props {
  gridRef: RefObject<AgGridReact>;
  setProposalsCount: Dispatch<SetStateAction<number>>;
  setTotalRowsCount: Dispatch<SetStateAction<number>>;
  setShowValidationErrorMessage: (value: boolean) => void;
}

interface AdjustmentData {
  accountId: number;
  proposedUnits: string;
}

const isHierarchicalRow = (params: CellClassParams<Adjustment>) => {
  return params?.data?.accountId === '';
};

const editableColumn = {
  editable: (params: EditableCallbackParams<Adjustment>) => {
    const isHierarchyRow = params?.data?.accountId === '';

    return params.node.isSelected() && !isHierarchyRow;
  },
  cellClass: (params: CellClassParams<Adjustment>) => {
    return [
      'editable-cell-text',
      isHierarchicalRow(params) ? '' : 'editable-cell',
    ];
  },
};
const symbolColumn = {
  filter: 'agNumberColumnFilter',
  type: ['numericColumn', 'symbolValueColumn'],
  autoHeight: true,
};
const volumeColumn = {
  filter: 'agNumberColumnFilter',
  type: ['numericColumn', 'volumeColumn'],
  autoHeight: true,
};
const percentageColumn = {
  filter: 'agNumberColumnFilter',
  type: ['numericColumn', 'percentageColumn'],
  autoHeight: true,
};

export const useProposalsGrid = (
  selectedPositions: (AccountLevelResponse | AccountGroupLevelResponse)[],
  security: SharedSecurityDetailsResponse,
  viewLevel: SharedViewLevelParamExtended,
  dispatch: AppDispatch,
  setProposalsCount: Dispatch<SetStateAction<number>>,
  setTotalRowsCount: Dispatch<SetStateAction<number>>,
  setShowValidationErrorMessage: (value: boolean) => void,
) => {
  const columnDefs = useMemo((): ColDef[] => {
    const commonColumnDefs = [
      { field: 'externalAccountId', autoHeight: true, hide: true },
      { field: 'instrument', autoHeight: true },
      { field: 'SEDOL', autoHeight: true, hide: true },
      { field: 'ISIN', autoHeight: true, hide: true },
      { field: 'security', autoHeight: true, hide: true },
      {
        field: 'marketPrice (CCY)',
        ...symbolColumn,
        hide: true,
      },
      {
        field: 'nativePrice',
        ...symbolColumn,
        autoHeight: true,
        hide: true,
      },
      { field: 'nativeCurrency', autoHeight: true, hide: true },
      { field: 'assetClassShortName', autoHeight: true, hide: true },
      {
        field: 'portfolioValue',
        ...symbolColumn,
        hide: true,
      },
      {
        field: 'currentValue (CCY)',
        ...symbolColumn,
      },
      {
        field: 'targetValue (CCY)',
        ...symbolColumn,
        ...editableColumn,
      },
      {
        field: 'proposedValue (CCY)',
        ...symbolColumn,
        ...editableColumn,
      },
      {
        field: 'targetNativeValue',
        ...symbolColumn,
      },
      {
        field: 'proposedNativeValue',
        ...symbolColumn,
      },
      {
        field: 'currentUnits',
        ...volumeColumn,
      },
      {
        field: 'targetUnits',
        ...volumeColumn,
        ...editableColumn,
      },
      {
        field: 'proposedUnits',
        ...volumeColumn,
        ...editableColumn,
      },
      {
        field: 'currentWeight',
        ...percentageColumn,
      },
      {
        field: 'targetWeight',
        ...percentageColumn,
        ...editableColumn,
      },
      {
        field: 'proposedWeight',
        ...percentageColumn,
        ...editableColumn,
      },
      {
        field: 'currentCashAvailable (CCY)',
        ...symbolColumn,
      },
      {
        field: 'proposedCashAvailable (CCY)',
        ...symbolColumn,
      },
      {
        field: 'currentCash %',
        ...percentageColumn,
      },
      {
        field: 'assetClass %',
        ...percentageColumn,
      },
      { field: 'taxType', autoHeight: true },
      {
        field: 'taxableGain/Loss (CCY)',
        ...symbolColumn,
      },
      {
        field: 'gain/Loss (CCY)',
        ...symbolColumn,
      },
      { field: 'status', autoHeight: true },
      { field: 'accountId', hide: true },
      { field: 'serviceType', hide: true },
    ];

    const accountGroupCodeColumnDef = [
      {
        field: 'accountGroupCode',
        autoHeight: true,
      },
    ];

    const accountAdjustmentsColumnDefs = [
      {
        field: 'portfolio',
        autoHeight: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      ...accountGroupCodeColumnDef,
      { field: 'accountGroup', autoHeight: true, sort: 'asc' as SortDirection },
      { field: 'account', autoHeight: true },
      ...commonColumnDefs,
    ];
    const groupAdjustmentsColumnDefs = [
      { field: 'portfolio', autoHeight: true, hide: true },
      ...accountGroupCodeColumnDef,
      {
        field: 'accountGroup',
        autoHeight: true,
        sort: 'asc' as SortDirection,
        checkboxSelection: true,
        hide: true,
      },
      { field: 'account', autoHeight: true, hide: true },
      ...commonColumnDefs,
    ];
    return viewLevel === 'accountGroups'
      ? groupAdjustmentsColumnDefs
      : accountAdjustmentsColumnDefs;
  }, [viewLevel]);

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: 'Account group & accounts',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
        suppressCount: true,
      } as IGroupCellRendererParams,
      cellClassRules: {
        'hierarchy-group-row': isHierarchicalRow,
      },
    };
  }, []);

  const createAccountRowData = useCallback(
    (accountProposal: AccountLevelResponse): Adjustment => {
      const targetNativeValue = calculateTargetNativeValue(
        accountProposal.units,
        security.nativePrice,
      );
      return {
        accountGroup: accountProposal.accountGroupName,
        accountGroupCode: accountProposal.accountGroupCode,
        portfolio: accountProposal.portfolio,
        account: accountProposal.account,
        externalAccountId: accountProposal.externalAccountId,
        instrument: security.description,
        SEDOL: security.sedol,
        ISIN: security.isin,
        security: `${security.code}.${security.exchange}`,
        'marketPrice (CCY)': security.marketPrice,
        nativePrice: security.nativePrice,
        nativeCurrency: security.nativeCurrency,
        assetClassShortName: security.assetClass,
        'currentValue (CCY)': accountProposal.holdingValue,
        'targetValue (CCY)': accountProposal.holdingValue,
        'proposedValue (CCY)': '0',
        targetNativeValue: targetNativeValue,
        proposedNativeValue: '0',
        currentUnits: accountProposal.units,
        targetUnits: accountProposal.units,
        proposedUnits: '0',
        currentWeight: accountProposal.weight,
        targetWeight: accountProposal.weight,
        proposedWeight: '0',
        'currentCashAvailable (CCY)': accountProposal.cashAmount,
        'proposedCashAvailable (CCY)': accountProposal.cashAmount,
        'currentCash %': accountProposal.cashPercentage,
        'assetClass %': '0',
        taxType: 'tbc',
        'taxableGain/Loss (CCY)': accountProposal.taxableGainLoss,
        'gain/Loss (CCY)': accountProposal.gainLoss,
        status: 'tbc',
        accountId: accountProposal.accountId?.toString(),
        portfolioValue: accountProposal.value,
        serviceType: accountProposal.serviceType,
        hierarchy: [accountProposal.accountGroupName, accountProposal.account],
      };
    },
    [security],
  );

  const accountGroupProposals: AccountGroupAdjustment[] = useMemo(() => [], []);
  const rowData: Adjustment[] = useMemo(() => {
    if (viewLevel === 'accountGroups') {
      for (const proposal of selectedPositions as AccountGroupLevelResponse[]) {
        const targetNativeValue = calculateTargetNativeValue(
          proposal.units,
          security.nativePrice,
        );
        const accountGroup: Adjustment = {
          accountGroup: proposal.accountGroupName,
          accountGroupCode: proposal.accountGroupCode,
          portfolio: proposal.portfolio,
          account: '',
          externalAccountId: '',
          instrument: security.description,
          SEDOL: security.sedol,
          ISIN: security.isin,
          security: `${security.code}.${security.exchange}`,
          'marketPrice (CCY)': security.marketPrice,
          nativePrice: security.nativePrice,
          nativeCurrency: security.nativeCurrency,
          assetClassShortName: security.assetClass,
          'currentValue (CCY)': proposal.holdingValue,
          'targetValue (CCY)': proposal.holdingValue,
          'proposedValue (CCY)': '0',
          targetNativeValue: targetNativeValue,
          proposedNativeValue: '0',
          currentUnits: proposal.units,
          targetUnits: proposal.units,
          proposedUnits: '0',
          currentWeight: proposal.weight,
          targetWeight: proposal.weight,
          proposedWeight: '0',
          'currentCashAvailable (CCY)': proposal.cashAmount,
          'proposedCashAvailable (CCY)': proposal.cashAmount,
          'currentCash %': proposal.cashPercentage,
          'assetClass %': '0',
          taxType: 'tbc',
          'taxableGain/Loss (CCY)': '',
          'gain/Loss (CCY)': '',
          status: 'tbc',
          accountId: '',
          portfolioValue: proposal.value,
          serviceType: proposal.serviceType,
          hierarchy: [proposal.accountGroupName],
        };
        const accounts: Adjustment[] = [];

        for (const accountProposal of proposal.accounts) {
          accounts.push(createAccountRowData(accountProposal));
        }

        accountGroupProposals.push({
          accountGroup: accountGroup,
          accounts: accounts,
        });
      }

      return accountGroupProposals.flatMap((accountGroupProposal) => {
        return [
          accountGroupProposal.accountGroup,
          ...accountGroupProposal.accounts,
        ];
      });
    } else {
      return (selectedPositions as AccountLevelResponse[]).map((position) =>
        createAccountRowData(position),
      );
    }
  }, [
    selectedPositions,
    security,
    viewLevel,
    accountGroupProposals,
    createAccountRowData,
  ]);

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent<AccountLevelResponse>) => {
      params.api.selectAll();

      dispatch(
        setOriginalProposals(
          viewLevel === 'accountGroups' ? accountGroupProposals : rowData,
        ),
      );
    },
    [dispatch, rowData, viewLevel, accountGroupProposals],
  );

  const getRowId = useCallback((params: GetRowIdParams<Adjustment>) => {
    return params.data.accountId !== null &&
      params.data.accountId !== undefined &&
      params.data.accountId !== ''
      ? params.data.accountId.toString()
      : params.data.accountGroupCode;
  }, []);

  const getDataPath = useCallback((data: Proposal) => {
    return data.hierarchy;
  }, []);

  const treeData = viewLevel === 'accountGroups';

  const handleOnSelectedAndFilteredEvent = useCallback(
    (event: SelectionChangedEvent | FilterChangedEvent) => {
      const filterAndSelectedRows: Proposals[] = [];
      let filteredTotalRows = 0;
      event.api.forEachNodeAfterFilter((node) => {
        const adjustmentData = node.data as AdjustmentData;
        if (node.isSelected() && adjustmentData.accountId) {
          filterAndSelectedRows.push(node.data);
        }
      });
      event.api.forEachNodeAfterFilter((node) => {
        const adjustmentData = node.data as AdjustmentData;
        if (adjustmentData.accountId) {
          filteredTotalRows++;
        }
      });
      dispatch(setSelectedAdjustments(filterAndSelectedRows));
      setProposalsCount(filterAndSelectedRows.length);
      setTotalRowsCount(filteredTotalRows);
      setShowValidationErrorMessage(false);
    },
    [
      dispatch,
      setProposalsCount,
      setTotalRowsCount,
      setShowValidationErrorMessage,
    ],
  );
  columnDefs.forEach((columnHeader) => {
    const headerName = columnHeader.field?.replace(
      'CCY',
      security.userCurrency,
    );
    if (headerName) {
      columnHeader.headerName = camelCaseToTitle(headerName);
    }
    columnHeader.cellClassRules = {
      ...columnHeader.cellClassRules,
      'hierarchy-group-row': isHierarchicalRow,
    };
  });

  const handleOnCellEditing = useCallback(
    (e: CellValueChangedEvent<Adjustment> | CellEditRequestEvent<Adjustment>) =>
      void handleManualAdjustment(e, viewLevel),
    [viewLevel],
  );
  return {
    columnDefs,
    rowData,
    handleOnSelectedAndFilteredEvent,
    onFirstDataRendered,
    getRowId,
    getDataPath,
    treeData,
    autoGroupColumnDef,
    handleOnCellEditing,
  };
};

function ProposalsGrid({
  gridRef,
  setProposalsCount,
  setTotalRowsCount,
  setShowValidationErrorMessage,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const selectedPositions = useAppSelector(
    (state: RootState) => state.accountGroups.selectedPositions,
  );

  const security = useAppSelector(
    (state: RootState) => state.securities.selectedSecurity,
  );
  const viewLevel = useAppSelector(
    (state: RootState) => state.accountGroups.viewLevel,
  );

  const {
    columnDefs,
    autoGroupColumnDef,
    rowData,
    onFirstDataRendered,
    handleOnSelectedAndFilteredEvent,
    getRowId,
    getDataPath,
    treeData,
    handleOnCellEditing,
  } = useProposalsGrid(
    selectedPositions,
    security,
    viewLevel,
    dispatch,
    setProposalsCount,
    setTotalRowsCount,
    setShowValidationErrorMessage,
  );

  // -1 = all row groups will be expanded by default
  const groupDefaultExpanded = -1;

  return (
    <IressPanel
      padding={IressPanel.Padding.Sm}
      background={IressPanel.Background.Default}
      className="grid-container"
      data-testid="proposals"
    >
      <AgGrid<Proposal>
        rowData={rowData}
        treeData={treeData}
        columnDefs={columnDefs}
        gridRef={gridRef}
        onFirstDataRendered={onFirstDataRendered}
        onSelectionChanged={handleOnSelectedAndFilteredEvent}
        onFilterChanged={handleOnSelectedAndFilteredEvent}
        getRowId={getRowId}
        getDataPath={getDataPath}
        autoGroupColumnDef={autoGroupColumnDef}
        groupDefaultExpanded={groupDefaultExpanded}
        groupSelectsChildren={true}
        onCellValueChanged={handleOnCellEditing}
        stopEditingWhenCellsLoseFocus={true}
        readOnlyEdit={true}
        onCellEditRequest={handleOnCellEditing}
      />
    </IressPanel>
  );
}

export default ProposalsGrid;
