import { IressButton } from '@iress/components-react';
import './Button.css';

/**
 * ButtonSecondaryWithOnClick component to display a secondary button with OnClick event
 * @returns ButtonSubmit component
 */
function ButtonSecondaryWithOnClick(props: {
  buttonText: string;
  dataTestId: string;
  onClick: () => void;
}) {
  return (
    <IressButton
      mode={IressButton.Mode.Secondary}
      type={IressButton.Type.Button}
      data-testid={props.dataTestId}
      onClick={props.onClick}
    >
      {props.buttonText}
    </IressButton>
  );
}
export default ButtonSecondaryWithOnClick;
