import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import {
  setIncludeZeroHoldings,
  setViewData,
} from '@app/features/AccountGroups';
import { IressCheckbox, IressPanel } from '@iress/components-react';
import { useCallback } from 'react';
import './style.css';
import { RootState } from '@app/app/store';

function CheckBox() {
  const dispatch = useAppDispatch();
  const includeZeroHoldings = useAppSelector(
    (state: RootState) => state.accountGroups.includeZeroHoldings,
  );

  const handleCheck = useCallback(
    ({ target }: { target: HTMLInputElement }) => {
      const value =
        target.checked === true ? 'withZeroHoldings' : 'withoutZeroHoldings';
      dispatch(setIncludeZeroHoldings(target.checked));
      dispatch(setViewData(value));
    },
    [dispatch],
  );

  return (
    <IressPanel
      className="iress-ag-grid-panel iress-pl--none"
      padding={IressPanel.Padding.Sm}
      background={IressPanel.Background.Page}
    >
      <IressCheckbox
        className="iress-checkbox-size"
        name="checkboxIncludeZeroHoldings"
        data-testid="test-checkboxIncludeZeroHoldings"
        value="accept"
        checked={includeZeroHoldings}
        onChange={handleCheck}
      >
        Include zero holdings
      </IressCheckbox>
    </IressPanel>
  );
}
export default CheckBox;
