import { useCallback, useEffect, useRef } from 'react';
import { setDataItems } from '@app/features/AccountGroups';
import { fetchFormattingSettings } from '@app/features/Sysadmin';
import { Outlet } from 'react-router-dom';
import { IressButton, IressPanel, showModal } from '@iress/components-react';
import { useAppDispatch, useAppSelector } from './hooks';
import { NoActionsPage } from '@app/pages';
import { useFlag } from '@unleash/proxy-client-react';
import { UNLEASH_TOGGLES } from './unleash';
import { DataItem } from '@app/types/accountGroups';
import { Modal } from '@app/components/Modal';
import { clickOutsideHandler, leaveBsaHandler } from './App.handlers';

export function useApp(
  dispatch: ReturnType<typeof useAppDispatch>,
  bsaRef: React.RefObject<HTMLDivElement>,
  groups: DataItem[],
  isSaveProgressEnabled: boolean,
  modalId: string,
  setShowModal: typeof showModal,
) {
  const checkData = useCallback(() => {
    if (window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA?.data) {
      dispatch(setDataItems(window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA.data));
      delete window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA;
    }
  }, [dispatch]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) =>
      clickOutsideHandler(event, bsaRef, modalId, setShowModal),
    [bsaRef, modalId, setShowModal],
  );

  const showNoActionsPage = useCallback(
    () => groups.length <= 0 && isSaveProgressEnabled,
    [groups, isSaveProgressEnabled],
  );
  const handleModalSaveButtonClick = useCallback(() => {
    // Create Save Button Handler functions
    // saveButtonHandler();
    leaveBsaHandler(modalId, setShowModal);
  }, [setShowModal, modalId]);

  const handleModalDeleteButtonClick = useCallback(() => {
    // Create Delete Button Handler functions
    // deleteButtonHandler();
    leaveBsaHandler(modalId, setShowModal);
  }, [setShowModal, modalId]);

  return {
    checkData,
    handleClickOutside,
    showNoActionsPage,
    handleModalSaveButtonClick,
    handleModalDeleteButtonClick,
  };
}

export const SAVE_SESSION_MODAL_ID = 'save-session-modal';

function App() {
  const bsaRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.accountGroups.dataItems);
  const isSaveProgressEnabled = useFlag(UNLEASH_TOGGLES.SaveProgress);

  const {
    checkData,
    showNoActionsPage,
    handleClickOutside,
    handleModalSaveButtonClick,
    handleModalDeleteButtonClick,
  } = useApp(
    dispatch,
    bsaRef,
    groups,
    isSaveProgressEnabled,
    SAVE_SESSION_MODAL_ID,
    showModal,
  );

  useEffect(() => {
    if (window.location.hash.includes('#thehub/bulk_security_actions')) {
      checkData();
    }
  }, [checkData]);

  useEffect(() => {
    void dispatch(fetchFormattingSettings());
  }, [dispatch]);

  useEffect(() => {
    if (isSaveProgressEnabled)
      document.addEventListener('mousedown', handleClickOutside);

    return () => {
      if (isSaveProgressEnabled)
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isSaveProgressEnabled]);

  const modelButtons = [
    <IressButton
      key="Cancel"
      data-testid="modal-btn-delete-exit"
      onClick={handleModalDeleteButtonClick}
      mode={IressButton.Mode.Secondary}
      type={IressButton.Type.Button}
    >
      Delete & exit
    </IressButton>,
    <IressButton
      key="save"
      data-testid="modal-btn-save-exit"
      onClick={handleModalSaveButtonClick}
      mode={IressButton.Mode.Primary}
      type={IressButton.Type.Button}
    >
      Save & exit
    </IressButton>,
  ];
  return (
    <IressPanel
      padding={IressPanel.Padding.Md}
      background={IressPanel.Background.Page}
      data-testid="app"
    >
      {showNoActionsPage() ? (
        <NoActionsPage />
      ) : (
        <div ref={bsaRef} style={{ height: 'calc(100vh - 150px)' }}>
          {isSaveProgressEnabled && (
            <Modal
              id={SAVE_SESSION_MODAL_ID}
              dataTestId={SAVE_SESSION_MODAL_ID}
              title="Save this Session?"
              message="If you save, this session will be available the next time you launch Bulk Security Actions (BSA)."
              buttons={modelButtons}
            />
          )}
          <Outlet />
        </div>
      )}
    </IressPanel>
  );
}

export default App;
