import { createHashRouter } from 'react-router-dom';
import { MainPage, InstructionsPage } from '@app/pages';
import App from './App';

export const router = createHashRouter(
  [
    {
      path: '/',
      element: <App />,
      children: [
        {
          path: '',
          element: <MainPage />,
        },
        {
          path: 'instructions',
          element: <InstructionsPage />,
        },
      ],
    },
  ],
  {
    basename:
      import.meta.env.MODE === 'production'
        ? '/thehub/bulk_security_actions'
        : '',
  },
);
