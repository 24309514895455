import { IressButton } from '@iress/components-react';
import { Link } from 'react-router-dom';
import './Button.css';

/**
 * ButtonPrimary component to display a primary mode button
 * @returns ButtonPrimary component
 */
function ButtonPrimary(props: {
  buttonText: string;
  link: string;
  dataTestid: string;
  onClick?: () => void;
}) {
  return (
    <Link to={props.link}>
      <IressButton
        mode={IressButton.Mode.Primary}
        type={IressButton.Type.Button}
        data-testid={props.dataTestid}
        onClick={props.onClick}
      >
        {props.buttonText}
      </IressButton>
    </Link>
  );
}
export default ButtonPrimary;
