import { SharedManualAdjustmentType } from '@bsa/shared-types';
import type { Column } from 'ag-grid-community';
import { camelCaseToTitle } from '@app/components/AgGrid/getters';
import Decimal from 'decimal.js';

export function getManualAdjustmentTypeFromColumn(column: Column) {
  /**
   * modify the column field to match the SharedManualAdjustmentType Enum
   */
  const columnId = column
    .getColId()
    .replace(' (CCY)', '') // Remove currency notation
    .replace('Weight', 'Percent'); // Replace 'Weight' with 'Percent'
  const fieldName = camelCaseToTitle(columnId).replaceAll(/\s/g, '');

  /**
   * convert SharedManualAdjustmentType Enum to array to check column field against its values
   */
  const manualAdjustmentTypes = new Object(SharedManualAdjustmentType);
  /**
   * check if the column field is a valid SharedManualAdjustmentType Enum
   */
  return fieldName in manualAdjustmentTypes ? fieldName : null;
}

export function calculateTargetNativeValue(
  targetUnits: string,
  nativePrice: string,
) {
  return new Decimal(targetUnits).times(new Decimal(nativePrice)).toString();
}
