import { showModal } from '@iress/components-react';

export const clickOutsideHandler = (
  event: MouseEvent,
  bsaRef: React.RefObject<HTMLDivElement>,
  modalId: string,
  setShowModal: typeof showModal,
) => {
  if (bsaRef.current && !bsaRef.current.contains(event.target as Node)) {
    // Trigger your event here
    console.log('Clicked outside the micro frontend');
    setShowModal(modalId, true);
  }
};

export function leaveBsaHandler(
  modalId: string,
  setShowModal: typeof showModal,
) {
  setShowModal(modalId, false);
  window.location.hash = 'thehub';
}
