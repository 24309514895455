import { PositionsGrid } from '@app/components/PositionsGrid';
import { CheckBox } from '@app/components/CheckBox';
import { SearchForm } from '@app/components/SearchForm';
import { SecurityDetails } from '@app/components/SecurityDetails';
import {
  IressCol,
  IressPanel,
  IressRow,
  IressStack,
} from '@iress/components-react';
import { MainPageTitle } from '@app/components/Titles';
import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { AppDispatch, RootState } from '@app/app/store';
import {
  AccountGroupLevelResponse,
  AccountLevelResponse,
} from '@bsa/shared-types';
import {
  AccountGroupsState,
  updatePositionSelection,
} from '@app/features/AccountGroups';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CtaPanel } from '@app/components/CtaPanel';
import ButtonContinue from '@app/components/Button/ButtonContinue';

export type Positions = AccountGroupLevelResponse | AccountLevelResponse;

export const useMainPage = (
  dispatch: AppDispatch,
  gridRef: RefObject<AgGridReact>,
  accountGroupItems: AccountGroupsState['accountGroupItems'],
  viewData: AccountGroupsState['viewData'],
  navigate: NavigateFunction,
  setShowValidationErrorMessage: (value: boolean) => void,
) => {
  const handleOnClick = useCallback(() => {
    let isFilteredNodeSelected = false;
    gridRef.current?.api.forEachNodeAfterFilter((node) => {
      if (node.isSelected()) {
        isFilteredNodeSelected = true;
      }
    });
    if (isFilteredNodeSelected) {
      if (gridRef.current) {
        const updatedSelection: Positions[] = [];
        gridRef.current.api.forEachNode((node) => {
          updatedSelection.push({ ...node.data, selected: node.isSelected() });
        });
        const updatedData = { ...accountGroupItems.data };
        updatedData[viewData] = updatedSelection;
        dispatch(updatePositionSelection(updatedData));
        navigate('/instructions');
      }
    } else {
      setShowValidationErrorMessage(true);
    }
  }, [
    gridRef,
    accountGroupItems.data,
    viewData,
    dispatch,
    setShowValidationErrorMessage,
    navigate,
  ]);

  return { handleOnClick, setShowValidationErrorMessage };
};

function MainPage() {
  const gridRef = useRef<AgGridReact>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountGroupItems = useAppSelector(
    (state: RootState) => state.accountGroups.accountGroupItems,
  );
  const viewData = useAppSelector(
    (state: RootState) => state.accountGroups.viewData,
  );
  const selectedPositions = useAppSelector(
    (state: RootState) => state.accountGroups.selectedPositions,
  );
  const viewLevel = useAppSelector(
    (state: RootState) => state.accountGroups.viewLevel,
  );
  const data = accountGroupItems.data[viewData];
  const { loading } = accountGroupItems;

  // selectedLevel should be singular not plural
  const selectedLevel =
    viewLevel === 'accountGroups' ? 'account group' : 'account';

  const [showValidationErrorMessage, setShowValidationErrorMessage] =
    useState<boolean>(false);

  const resetErrorMessage = useCallback(() => {
    setShowValidationErrorMessage(false);
  }, []);

  const errorMessage = `Please select at least one ${selectedLevel} before proceeding`;

  const { handleOnClick } = useMainPage(
    dispatch,
    gridRef,
    accountGroupItems,
    viewData,
    navigate,
    setShowValidationErrorMessage,
  );

  useEffect(() => {
    if (
      gridRef.current &&
      gridRef.current.api?.getSelectedRows().length !== 0
    ) {
      setShowValidationErrorMessage(false);
    }
  }, [selectedPositions]);

  return (
    <div
      style={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        gap: '8px',
      }}
    >
      <IressRow>
        <IressCol>
          <MainPageTitle />
        </IressCol>
        {data.length > 0 && loading === 'succeeded' ? (
          <IressCol>
            <CtaPanel
              selectedRowCount={selectedPositions.length.toString()}
              totalRowCount={(
                gridRef.current?.api?.getDisplayedRowCount() ?? 0
              ).toString()}
              selectedLevel={selectedLevel}
              showErrorMessage={showValidationErrorMessage}
              errorMessage={errorMessage}
            >
              <ButtonContinue onClick={handleOnClick} />
            </CtaPanel>
          </IressCol>
        ) : (
          <></>
        )}
      </IressRow>
      <IressPanel
        padding={IressPanel.Padding.Sm}
        background={IressPanel.Background.Default}
      >
        <IressStack gutter={IressStack.Gutter.Sm}>
          <SearchForm resetErrorMessage={resetErrorMessage} />
          <SecurityDetails />
        </IressStack>
      </IressPanel>
      <CheckBox />
      <PositionsGrid gridRef={gridRef} />
    </div>
  );
}
export default MainPage;
