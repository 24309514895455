import {
  Adjustment,
  SharedInstructionAccountGroupDecreaseToResponse,
  SharedInstructionDecreaseToResponse,
} from '../../index';
import { ZERO, HUNDRED, THOUSAND, ONE, TEN } from '../constants';

export const sharedMockServiceType = {
  Discretionary: 'Discretionary',
  Advisory: 'Advisory',
  Execution: 'Execution Only',
};

const defaultAdjustment: Adjustment = {
  'assetClass %': ZERO.toString(),
  'currentCash %': HUNDRED.times(9).toString(),
  'currentCashAvailable (CCY)': HUNDRED.toString(),
  'currentValue (CCY)': THOUSAND.toString(),
  'gain/Loss (CCY)': ZERO.toString(),
  'marketPrice (CCY)': ONE.toString(),
  'proposedCashAvailable (CCY)': ZERO.toString(),
  'proposedValue (CCY)': ZERO.toString(),
  'targetValue (CCY)': THOUSAND.toString(),
  'taxableGain/Loss (CCY)': ZERO.toString(),
  account: 'account-description-1',
  accountGroup: 'ALPHA',
  accountGroupCode: 'ALPHA',
  accountId: '123456',
  assetClassShortName: 'EMERGING MARKETS EQUITY',
  currentUnits: HUNDRED.toString(),
  currentWeight: TEN.toString(),
  externalAccountId: 'account-code-1',
  hierarchy: ['ALPHA', 'account-description-1'],
  instrument: 'SECURITY - LSE',
  ISIN: 'BG11VIASAT18',
  nativeCurrency: 'GBP',
  nativePrice: ONE.toString(),
  portfolio: 'Johnson, Jon',
  portfolioValue: THOUSAND.toString(),
  proposedNativeValue: ZERO.toString(),
  proposedUnits: ZERO.toString(),
  proposedWeight: ZERO.toString(),
  security: 'SEC.LSE',
  SEDOL: '',
  serviceType: sharedMockServiceType.Discretionary,
  status: 'tbc',
  targetNativeValue: ZERO.toString(),
  targetUnits: HUNDRED.toString(),
  targetWeight: TEN.toString(),
  taxType: 'tbc',
};
export const sharedCreateMockAccountAdjustment = (
  overrides: Partial<Adjustment> = {}
): Adjustment => {
  const completeData = { ...defaultAdjustment, ...overrides };

  const hierarchy = [completeData.accountGroup, completeData.account];

  return {
    ...completeData,
    hierarchy,
  };
};

export function sharedCreateMockAccountGroupAdjustment(
  overrides = {}
): Adjustment {
  const accountGroupDefaults = sharedCreateMockAccountAdjustment();
  const completeData = { ...accountGroupDefaults, ...overrides };
  return {
    ...completeData,
    account: '',
    accountId: '',
    externalAccountId: '',
    instrument: '',
    ISIN: '',
    hierarchy: [completeData.accountGroup],
  };
}

const decreaseToValues = {
  proposedNativeValue: THOUSAND.mul(-1).toString(),
  proposedUnits: HUNDRED.mul(-1).toString(),
  proposedWeight: TEN.mul(-1).toString(),
  'proposedValue (CCY)': THOUSAND.mul(-1).toString(),
  targetNativeValue: ZERO.toString(),
  targetUnits: ZERO.toString(),
  targetWeight: ZERO.toString(),
  'targetValue (CCY)': ZERO.toString(),
};

export const sharedMockInstructionDecreaseToResponse: SharedInstructionDecreaseToResponse =
  {
    proposals: [
      sharedCreateMockAccountAdjustment({ ...decreaseToValues }),
      sharedCreateMockAccountAdjustment({
        account: 'account-description-2',
        accountId: '123455',
        externalAccountId: 'account-code-2',
        portfolio: 'Johnson, Jane',
        hierarchy: ['ALPHA', 'account-description-2'],
        ...decreaseToValues,
      }),
    ],
  };

export const sharedMockInstructionAccountGroupDecreaseToResponse: SharedInstructionAccountGroupDecreaseToResponse =
  {
    proposals: [
      {
        accountGroup: sharedCreateMockAccountGroupAdjustment({
          ...decreaseToValues,
        }),
        accounts: sharedMockInstructionDecreaseToResponse.proposals,
      },
    ],
  };
