import axiosInstance from '@app/utils/axiosInstance';
import { SharedSysadminFormattingResponse } from '@bsa/shared-types';

export async function getFormattingSettings(
  setErrorMessage?: (message: string) => void,
): Promise<SharedSysadminFormattingResponse> {
  try {
    const response = await axiosInstance.get('/sysadmin/formatting');
    return response.data;
  } catch {
    if (setErrorMessage) {
      setErrorMessage('Failed to fetch formatting settings');
    }
    throw new Error('Failed to fetch formatting settings');
  }
}
