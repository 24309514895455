import {
  SharedSetting,
  SharedSysadminFormattingResponse,
} from '@bsa/shared-types';

/**
 * These defaults are used if there is no matching configuration returned from the resourceful endpoint.
 * We omit element and display, which are not used in the numberFormatter targetsets application.
 */
export const NUMBER_FORMATTING_FALLBACK: SharedSetting = {
  dp: 2,
  maxdp: -1,
  pospre: '',
  negpre: '-',
  pospost: '',
  negpost: '',
  comma: true,
};

/**
 * These defaults are used before we if there is no matching configuration returned from the resourceful endpoint.
 */
export const USER_FORMATTING_SETTINGS_FALLBACK: SharedSysadminFormattingResponse =
  {
    numeric: {
      general: NUMBER_FORMATTING_FALLBACK,
      reporting: NUMBER_FORMATTING_FALLBACK,
      portfolio: NUMBER_FORMATTING_FALLBACK,
    },
    money: {
      general: NUMBER_FORMATTING_FALLBACK,
      reporting: NUMBER_FORMATTING_FALLBACK,
      portfolio: NUMBER_FORMATTING_FALLBACK,
    },
    percent: {
      general: NUMBER_FORMATTING_FALLBACK,
      reporting: NUMBER_FORMATTING_FALLBACK,
      portfolio: NUMBER_FORMATTING_FALLBACK,
    },
    'portfolio:market_value': {
      general: NUMBER_FORMATTING_FALLBACK,
      reporting: NUMBER_FORMATTING_FALLBACK,
      portfolio: NUMBER_FORMATTING_FALLBACK,
    },
    'portfolio:unit_price': {
      general: NUMBER_FORMATTING_FALLBACK,
      reporting: NUMBER_FORMATTING_FALLBACK,
      portfolio: NUMBER_FORMATTING_FALLBACK,
    },
    'portfolio:volume': {
      general: NUMBER_FORMATTING_FALLBACK,
      reporting: NUMBER_FORMATTING_FALLBACK,
      portfolio: NUMBER_FORMATTING_FALLBACK,
    },
    'portfolio:volume_fund': {
      general: NUMBER_FORMATTING_FALLBACK,
      reporting: NUMBER_FORMATTING_FALLBACK,
      portfolio: NUMBER_FORMATTING_FALLBACK,
    },
  };
