import {
  IressCombobox,
  IressIcon,
  MenuItemMeta,
} from '@iress/components-react';
import './ComboBox.css';

interface Props {
  handleInputChange: (query: string) => Promise<MenuItemMeta[]>;
  noResultsText?: string;
  selectedOption?: MenuItemMeta;
}

/**
 * ComboBox component to search and select a security
 * @returns ComboBox component
 */
function ComboBox({
  handleInputChange,
  noResultsText,
  selectedOption,
}: Readonly<Props>) {
  return (
    <IressCombobox
      data-testid="test-combobox"
      name="securityId"
      className="combobox"
      resultLimitDesktop={15}
      resultLimitMobile={10}
      placeholder="Search security by code, name, SEDOL, ISIN"
      required
      valueMissingText="Please select a security"
      options={handleInputChange}
      noResultsText={noResultsText}
      selectedOption={selectedOption}
    >
      <IressIcon name="search" slot={IressCombobox.Slot.Append} />
    </IressCombobox>
  );
}
export default ComboBox;
