import {
  InputKeyboardEvent,
  IressField,
  IressInput,
} from '@iress/components-react';

function InputPercentage(props: {
  handleChange: (event: InputKeyboardEvent) => void;
}) {
  return (
    <IressField
      label="Percentage"
      key="percentage"
      data-testid="percentage-label"
      valueMissing="Please enter a percentage"
      patternMismatch="Please enter a value between 0.00 and 100.00"
    >
      <IressInput
        type={IressInput.Type.Text}
        data-testid="percentage-input"
        width={IressInput.Width.Four}
        required
        pattern="^(100\.0|100\.00|100)|([0-9]{1,2}){1}(\.[0-9]{1,2}){0,2}$"
        maxlength={6}
        onChange={props.handleChange}
      >
        <span slot={IressInput.Slot.Append}>%</span>
      </IressInput>
    </IressField>
  );
}
export default InputPercentage;
