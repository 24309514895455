import { ValueGetterParams } from 'ag-grid-community';

/**
 * A field in the table data, for example "min_value_amount".
 */
/**
 * The AG-Grid representation of the row data passed into the table. For example: _val: "123.45".
 */

type NumberRecord = Record<string, number>;

export const numberValueGetter = ({
  data,
  colDef,
}: ValueGetterParams<NumberRecord>): number | null => {
  if (!data || !colDef.field) {
    return null;
  }
  const numberValue = data[colDef.field];
  return Number.isNaN(Number(numberValue)) ? null : numberValue;
};

export function camelCaseToTitle(s: string) {
  const result = s.replaceAll(/([a-z])([A-Z])/g, '$1 $2');
  return result.charAt(0).toUpperCase() + result.slice(1);
}
