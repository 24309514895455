import { ButtonMode, IressButton } from '@iress/components-react';
import './Button.css';

interface ButtonSubmitProps {
  buttonText: string;
  mode: ButtonMode;
  dataTestId: string;
  isLoaderVisible: boolean;
  raiseEvent?: () => void;
}

function ButtonSubmit(props: Readonly<ButtonSubmitProps>) {
  return (
    <IressButton
      loading={props.isLoaderVisible}
      mode={props.mode}
      type={IressButton.Type.Submit}
      data-testid={props.dataTestId}
      onClick={props.raiseEvent}
    >
      {props.buttonText}
    </IressButton>
  );
}
export default ButtonSubmit;
