import {
  FilterSelectEventDetail,
  IressField,
  IressFilter,
} from '@iress/components-react';

function SelectExcludeServiceType(
  props: Readonly<{
    onSelect: (e: FilterSelectEventDetail) => void;
  }>,
) {
  return (
    <IressField
      label="Exclude Service Types"
      data-testid="exclude-service-type-label"
    >
      <IressFilter
        label="Select an option"
        data-testid="exclude-service-type-filter"
        onSelect={props.onSelect}
        multiSelect
        options={[
          { value: 'Advisory', label: 'Advisory' },
          { value: 'Discretionary', label: 'Discretionary' },
          { value: 'Execution Only', label: 'Execution Only' },
        ]}
      />
    </IressField>
  );
}
export default SelectExcludeServiceType;
