import Decimal from 'decimal.js';
import {
  SharedSetting,
  SharedSysadminFormattingResponse,
} from '@bsa/shared-types';
import { NUMBER_FORMATTING_FALLBACK } from './default';
const EMPTY_STRING = '';
const NEGATIVE_ONE = -1;

const formatWholeNumberWithGroupSeparator = (
  value: string,
  separator: string,
): string => {
  const result = [];
  let remainingLength = value.length;
  const DIGITS_IN_GROUPING = 3;

  while (remainingLength > DIGITS_IN_GROUPING) {
    remainingLength -= DIGITS_IN_GROUPING;
    result.push(
      value.slice(remainingLength, remainingLength + DIGITS_IN_GROUPING),
      separator,
    );
  }

  result.push(value.slice(0, remainingLength));
  result.reverse();
  return result.join('');
};

type AdditionalFormattingOptions = { decimal?: string; separator?: string };
type FormatDecimalAsStringProperties = {
  absoluteValue: Decimal;
  decimalPlaces?: number;
  suffix?: string;
  prefix?: string;
  separateDigitGroups?: boolean;
} & AdditionalFormattingOptions;
const FIX_TO_TWO_PLACES = 2;
const formatDecimalAsString = ({
  suffix = '',
  decimalPlaces = FIX_TO_TWO_PLACES,
  absoluteValue,
  decimal = '.',
  separateDigitGroups = true,
  separator = ',',
  prefix = '',
}: FormatDecimalAsStringProperties): string => {
  const formattedNumber =
    decimalPlaces >= 0
      ? absoluteValue.toFixed(decimalPlaces, Decimal.ROUND_HALF_UP)
      : absoluteValue.toString();

  const [wholeNumber, formattedDecimalNumber] = formattedNumber.split('.');
  // Below handles edge case where formatted number is 0 and formatted decimal number is undefined
  const decimalNumber = formattedDecimalNumber ?? '00';
  const formattedWholeNumber = separateDigitGroups
    ? formatWholeNumberWithGroupSeparator(wholeNumber, separator)
    : wholeNumber;
  return `${prefix}${formattedWholeNumber}${decimal}${decimalNumber}${suffix}`;
};

const writeDecimal = (
  value: Decimal,
  sysAdminFormatting: SharedSetting,
): string => {
  const isNegative = value.isNegative();
  const absoluteValue = value.abs();

  const { dp, maxdp, negpost, negpre, pospost, pospre, comma } =
    sysAdminFormatting;
  const prefix = isNegative ? negpre : pospre;
  const suffix = isNegative ? negpost : pospost;
  const decimalPlaces = dp === NEGATIVE_ONE ? maxdp : dp;

  // Construct results backwards
  return formatDecimalAsString({
    suffix,
    decimalPlaces,
    absoluteValue,
    separateDigitGroups: comma,
    prefix,
  });
};

type NumberFormatterProperties = {
  value?: number | null;
  sysAdminFormattings: SharedSysadminFormattingResponse;
  sysAdminFormattingElement: string;
  reverseSign?: boolean;
};

export const numberFormatter = ({
  value,
  sysAdminFormattings,
  sysAdminFormattingElement,
  reverseSign = false,
}: NumberFormatterProperties): string => {
  if (value !== 0 && !value) {
    return EMPTY_STRING;
  }

  const absoluteDecimal = new Decimal(value);
  const signedDecimal = reverseSign
    ? absoluteDecimal.mul(NEGATIVE_ONE)
    : absoluteDecimal;
  const formattingForElement =
    sysAdminFormattings[sysAdminFormattingElement]?.portfolio ??
    NUMBER_FORMATTING_FALLBACK;
  return writeDecimal(signedDecimal, formattingForElement);
};

export const symbolValueFormatter =
  (settings: SharedSysadminFormattingResponse) =>
  (value?: number | null, reverseSign = false): string =>
    numberFormatter({
      value,
      sysAdminFormattings: settings,
      sysAdminFormattingElement: 'portfolio:market_value',
      reverseSign,
    });
export const volumeFormatter =
  (settings: SharedSysadminFormattingResponse) =>
  (value?: number | null, reverseSign = false): string =>
    numberFormatter({
      value,
      sysAdminFormattings: settings,
      sysAdminFormattingElement: 'portfolio:volume',
      reverseSign,
    });
export const priceFormatter =
  (settings: SharedSysadminFormattingResponse) =>
  (value?: number | null): string =>
    numberFormatter({
      value,
      sysAdminFormattings: settings,
      sysAdminFormattingElement: 'portfolio:unit_price',
    });
export const generalNumericFormatter =
  (settings: SharedSysadminFormattingResponse) =>
  (value?: number | null): string =>
    numberFormatter({
      value,
      sysAdminFormattings: settings,
      sysAdminFormattingElement: 'numeric',
    });
export const percentageFormatter =
  (settings: SharedSysadminFormattingResponse) =>
  (value?: number | null): string =>
    numberFormatter({
      value,
      sysAdminFormattings: settings,
      sysAdminFormattingElement: 'percent',
    });
