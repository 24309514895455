import { SharedViewLevelParamExtended } from '@app/types/sharedViewLevelParamExtended';
import axiosInstance, { chunkArray } from '@app/utils/axiosInstance';
import type { SharedAccountsResponse } from '@bsa/shared-types';
import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';

const CHUNK_SIZE = 20;
const PARALLEL_LIMIT = 2;

export async function searchAccountsHoldingSecurityService(
  securityId: number,
  accountGroups: string[],
  viewLevel: SharedViewLevelParamExtended,
): Promise<SharedAccountsResponse> {
  const chunks = chunkArray(accountGroups, CHUNK_SIZE);
  const combinedData: SharedAccountsResponse = {
    withoutZeroHoldings: [],
    withZeroHoldings: [],
  };

  const traceId = uuidv4();

  for (let i = 0; i < chunks.length; i += PARALLEL_LIMIT) {
    const chunkBatch = chunks.slice(i, i + PARALLEL_LIMIT);
    const promises = chunkBatch.map((groupChunk) => {
      return axiosInstance.get(`/accounts`, {
        params: {
          security_id: securityId,
          accountGroups: groupChunk,
          viewLevel,
        },
        headers: {
          'x-trace-id': traceId,
        },
      });
    });

    const responses = await Promise.all(promises);

    responses.forEach((response: AxiosResponse<SharedAccountsResponse>) => {
      combinedData.withZeroHoldings.push(...response.data.withZeroHoldings);
      combinedData.withoutZeroHoldings.push(
        ...response.data.withoutZeroHoldings,
      );
    });
  }

  return combinedData;
}
