import {
  IressField,
  IressSelect,
  SelectChangeEvent,
} from '@iress/components-react';

function SelectInstruction(props: {
  handleChange: (e: SelectChangeEvent) => void;
  selectedLevel: string;
}) {
  return (
    <IressField
      label="Instruction"
      hint={`Adjustments will be applied at an ${props.selectedLevel} level`}
      data-testid="instruction-label"
      valueMissing="Please select an instruction"
    >
      {
        <IressSelect
          required
          placeholder="Select an instruction"
          data-testid="instruction-select"
          onChange={props.handleChange}
        >
          <option value="increase-position-to">Increase position to</option>
          <option value="decrease-position-to">Decrease position to</option>
          <option value="sell-all">Sell all</option>
        </IressSelect>
      }
    </IressField>
  );
}

export default SelectInstruction;
