import {
  IressField,
  IressSelect,
  SelectChangeEvent,
} from '@iress/components-react';

function SelectAllocationType(
  props: Readonly<{
    handleChange: (e: SelectChangeEvent) => void;
  }>,
) {
  return (
    <IressField label="Allocation Types" data-testid="allocation-type-label">
      <IressSelect
        required
        placeholder="Select an option"
        data-testid="allocation-type-select"
        onChange={props.handleChange}
      >
        <option value="pro-rata">Pro Rata</option>
        <option value="equal">Equal</option>
      </IressSelect>
    </IressField>
  );
}
export default SelectAllocationType;
