import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import axiosRetry from 'axios-retry';

const axiosInstance = axios.create({
  baseURL: '/services/primary-xpp-bsa/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosRetry(axiosInstance, { retries: 2 });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    throw error;
  },
);

export const cancelTokens: Map<string, CancelTokenSource> = new Map();

export async function makeCancelableRequest<T>(
  key: string,
  config: AxiosRequestConfig,
): Promise<T> {
  // Cancel the previous request with the same key if it exists
  const cancelToken = cancelTokens.get(key);
  if (cancelToken) {
    cancelToken.cancel('Cancelled due to new request');
  }

  // Create a new CancelToken
  const newCancelToken = axios.CancelToken.source();
  cancelTokens.set(key, newCancelToken);

  const response = await axiosInstance({
    ...config,
    cancelToken: newCancelToken.token,
  });

  // Clear the token after the request is completed
  cancelTokens.delete(key);

  return response.data;
}

// Function to chunk the array
export function chunkArray(array: unknown[], size: number) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}
// Function to chunk the array by nested array size
export function chunkByNestedArraySize<T>(
  array: T[],
  nestedArrayKey: keyof T,
  size: number,
) {
  const result = [];
  let currentChunk: T[] = [];
  let currentChunkSize = 0;

  for (const item of array) {
    const groupSize = (item[nestedArrayKey] as unknown as unknown[]).length;

    if (currentChunkSize + groupSize > size && currentChunk.length > 0) {
      result.push(currentChunk);
      currentChunk = [];
      currentChunkSize = 0;
    }

    currentChunk.push(item);
    currentChunkSize += groupSize;
  }

  if (currentChunk.length > 0) {
    result.push(currentChunk);
  }

  return result;
}
export default axiosInstance;
