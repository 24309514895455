import type { SharedSecurityDetailsResponse } from '@bsa/shared-types';
import { searchSecuritiesService } from '@app/services/securities';
import { modifySecuritiesToMenuItems } from '@app/utils';
import { useCallback, useState } from 'react';

export function useComboBoxInputChange() {
  const [securities, setSecurities] = useState<SharedSecurityDetailsResponse[]>(
    [],
  );
  const [noResultText, setNoResultText] = useState<string>('');

  const handleComboBoxInputChange = useCallback(async (query: string) => {
    let securitiesData: SharedSecurityDetailsResponse[] = [];
    if (query.length > 2) {
      try {
        securitiesData = await searchSecuritiesService(query, setNoResultText);
      } catch {
        securitiesData = [];
      }
      setSecurities(securitiesData);
    } else {
      setNoResultText('Please enter at least 3 characters');
    }
    return modifySecuritiesToMenuItems(securitiesData);
  }, []);

  return { handleComboBoxInputChange, securities, noResultText };
}
