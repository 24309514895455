import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DataItem } from '@app/types/accountGroups';
import accountGroupJson from '@app/__mocks__/accountGroups.mocks.json';
import { searchAccountsHoldingSecurityService } from '@app/services/accounts';
import type {
  SharedAccountsResponse,
  AccountLevelResponse,
  AccountGroupLevelResponse,
  Adjustment,
  SharedAccountAdjustmentDetails,
  SharedProposalResponse,
  AccountGroupAdjustment,
} from '@bsa/shared-types';
import { SharedViewLevelParamExtended } from '@app/types/sharedViewLevelParamExtended';
import { createAccountAdjustmentOnProposal } from '@app/services/proposals';

export interface AccountGroupsState {
  dataItems: DataItem[];
  accountGroupItems: {
    data: SharedAccountsResponse;
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string;
  };
  viewData: 'withoutZeroHoldings' | 'withZeroHoldings';
  selectedPositions: (AccountLevelResponse | AccountGroupLevelResponse)[];
  selectedAdjustments: (AccountLevelResponse | AccountGroupLevelResponse)[];
  selectedProposals: Adjustment[];
  includeZeroHoldings: boolean;
  viewLevel: SharedViewLevelParamExtended;
  originalProposals: (Adjustment | AccountGroupAdjustment)[];
  adjustmentProposals: SharedAccountAdjustmentDetails[];
  adjustmentProposalResponses: {
    data: SharedProposalResponse;
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string;
  };
}

const initialAccountGroups: DataItem[] =
  process.env.NODE_ENV === 'development' ? accountGroupJson : [];

export const initialAccountGroupState: AccountGroupsState = {
  dataItems: initialAccountGroups,
  accountGroupItems: {
    data: { withoutZeroHoldings: [], withZeroHoldings: [] },
    loading: 'idle',
    error: '',
  },
  viewData: 'withoutZeroHoldings',
  selectedPositions: [],
  selectedProposals: [],
  selectedAdjustments: [],
  includeZeroHoldings: false,
  viewLevel: '',
  originalProposals: [],
  adjustmentProposals: [],
  adjustmentProposalResponses: {
    data: { proposals: [], failedProposals: [] },
    loading: 'idle',
    error: '',
  },
};

export const fetchAccountsHoldingSecurity = createAsyncThunk(
  'accountGroups/fetchAccountsHoldingSecurity',
  async ({
    selectedSecurityId,
    accountGroupCodes,
    viewLevel,
  }: {
    selectedSecurityId: number;
    accountGroupCodes: string[];
    viewLevel: SharedViewLevelParamExtended;
  }) => {
    return searchAccountsHoldingSecurityService(
      selectedSecurityId,
      accountGroupCodes,
      viewLevel,
    );
  },
);

export const createAdjustmentOnProposal = createAsyncThunk(
  'accountGroups/createAccountAdjustmentOnProposal',
  async ({
    selectedSecurityId,
    adjustments,
  }: {
    selectedSecurityId: number;
    adjustments: SharedAccountAdjustmentDetails[];
  }) => {
    return createAccountAdjustmentOnProposal(selectedSecurityId, adjustments);
  },
);

export const AccountGroupsSlice = createSlice({
  name: 'AccountGroups',
  initialState: initialAccountGroupState,
  reducers: {
    setDataItems: (state, action: PayloadAction<DataItem[]>) => {
      state.dataItems = action.payload;
    },
    setViewData: (
      state,
      action: PayloadAction<AccountGroupsState['viewData']>,
    ) => {
      state.viewData = action.payload;
    },
    setSelectedPositions: (
      state,
      action: PayloadAction<AccountGroupsState['selectedPositions']>,
    ) => {
      state.selectedPositions = action.payload;
    },
    setSelectedProposals: (
      state,
      action: PayloadAction<AccountGroupsState['selectedProposals']>,
    ) => {
      state.selectedProposals = action.payload;
    },
    setSelectedAdjustments: (
      state,
      action: PayloadAction<AccountGroupsState['selectedAdjustments']>,
    ) => {
      state.selectedAdjustments = action.payload;
    },
    setIncludeZeroHoldings: (
      state,
      action: PayloadAction<AccountGroupsState['includeZeroHoldings']>,
    ) => {
      state.includeZeroHoldings = action.payload;
    },
    setViewLevel: (
      state,
      action: PayloadAction<AccountGroupsState['viewLevel']>,
    ) => {
      state.viewLevel = action.payload;
    },
    setOriginalProposals: (
      state,
      action: PayloadAction<AccountGroupsState['originalProposals']>,
    ) => {
      state.originalProposals = action.payload;
    },
    updatePositionSelection: (
      state,
      action: PayloadAction<SharedAccountsResponse>,
    ) => {
      state.accountGroupItems.data = action.payload;
    },
    updateAdjustmentProposals: (
      state,
      action: PayloadAction<SharedAccountAdjustmentDetails[]>,
    ) => {
      state.adjustmentProposals = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountsHoldingSecurity.fulfilled, (state, action) => {
        state.accountGroupItems.data = action.payload;
        state.accountGroupItems.loading = 'succeeded';
      })
      .addCase(fetchAccountsHoldingSecurity.pending, (state) => {
        state.accountGroupItems.data = {
          withoutZeroHoldings: [],
          withZeroHoldings: [],
        };
        state.accountGroupItems.loading = 'pending';
      })
      .addCase(fetchAccountsHoldingSecurity.rejected, (state, action) => {
        state.accountGroupItems.data = {
          withoutZeroHoldings: [],
          withZeroHoldings: [],
        };
        state.accountGroupItems.loading = 'failed';
        state.accountGroupItems.error = action.error.message as string;
      })
      .addCase(createAdjustmentOnProposal.fulfilled, (state, action) => {
        state.adjustmentProposalResponses.data = action.payload;
        state.adjustmentProposalResponses.loading = 'succeeded';
      })
      .addCase(createAdjustmentOnProposal.pending, (state) => {
        state.adjustmentProposalResponses.data = {
          proposals: [],
          failedProposals: [],
        };
        state.adjustmentProposalResponses.loading = 'pending';
      })
      .addCase(createAdjustmentOnProposal.rejected, (state, action) => {
        state.adjustmentProposalResponses.data = {
          proposals: [],
          failedProposals: [],
        };
        state.adjustmentProposalResponses.loading = 'failed';
        state.adjustmentProposalResponses.error = action.error
          .message as string;
      });
  },
});

export const {
  setDataItems,
  setViewData,
  setSelectedPositions,
  setSelectedProposals,
  setSelectedAdjustments,
  updatePositionSelection,
  setOriginalProposals,
  updateAdjustmentProposals,
  setIncludeZeroHoldings,
  setViewLevel,
} = AccountGroupsSlice.actions;

export default AccountGroupsSlice.reducer;
