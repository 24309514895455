import { IressText } from '@iress/components-react';

function InstructionsPageTitle() {
  return (
    <>
      <IressText element={IressText.Element.H2} noGutter>
        Bulk security actions 2 of 2
      </IressText>
      <IressText element={IressText.Element.Span} noGutter>
        Apply an instruction and make adjustments
      </IressText>
    </>
  );
}

export default InstructionsPageTitle;
