import {
  IressInline,
  IressStack,
  IressText,
  IressValidationMessage,
} from '@iress/components-react';
import './CtaPanel.css';

function CtaPanel(
  props: Readonly<{
    selectedRowCount: string;
    totalRowCount: string;
    selectedLevel: string;
    children: React.ReactNode;
    showErrorMessage: boolean;
    errorMessage: string;
  }>,
) {
  return (
    <IressStack>
      <IressInline
        gutter={IressInline.Gutter.Sm}
        verticalAlign={IressInline.VerticalAlign.Middle}
        horizontalAlign={IressInline.HorizontalAlign.Right}
      >
        <IressText element={IressText.Element.Span} data-testid="selected-text">
          <b>{props.selectedRowCount}</b> of <b>{props.totalRowCount}</b>{' '}
          {props.selectedLevel}s selected
        </IressText>
        {props.children}
      </IressInline>
      <IressInline
        className="ctapanel"
        horizontalAlign={IressInline.HorizontalAlign.Right}
      >
        {props.showErrorMessage && (
          <IressValidationMessage
            data-testid="validation-error"
            status={IressValidationMessage.Status.Error}
          >
            {props.errorMessage}
          </IressValidationMessage>
        )}
      </IressInline>
    </IressStack>
  );
}
export default CtaPanel;
