import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { AccountGroupsReducer } from '@app/features/AccountGroups';
import { SecuritiesReducer } from '@app/features/Securities';
import { SysAdminReducer } from '@app/features/Sysadmin';

const rootReducer = combineReducers({
  accountGroups: AccountGroupsReducer,
  securities: SecuritiesReducer,
  sysadmin: SysAdminReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];
