import { IressText } from '@iress/components-react';

function MainPageTitle() {
  return (
    <>
      <IressText element={IressText.Element.H2} noGutter>
        Bulk security actions 1 of 2
      </IressText>
      <IressText element={IressText.Element.Span} noGutter>
        Filter by security and select account groups or accounts
      </IressText>
    </>
  );
}

export default MainPageTitle;
