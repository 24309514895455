import {
  IressCol,
  IressForm,
  IressRow,
  IressInline,
  SelectChangeEvent,
  ButtonMode,
  showModal,
  InputKeyboardEvent,
  FilterSelectEventDetail,
  MenuItemMeta,
} from '@iress/components-react';
import { useAppSelector } from '@app/app/hooks';
import { RootState } from '@app/app/store';
import React, { RefObject, useCallback, useRef } from 'react';
import {
  SelectInstruction,
  SelectAllocationType,
  SelectExcludeServiceType,
} from '../Select';
import { InputUnitPrice, InputPercentage } from '../Input';
import { ButtonSecondaryWithOnClick, ButtonSubmit } from '../Button';
import { AgGridReact } from 'ag-grid-react';
import {
  AccountGroupAdjustment,
  Adjustment,
  AllocationType,
  InstructionAdjustmentType,
  SharedInstructionAccountGroupAdjustment,
  SharedInstructionAdjustment,
} from '@bsa/shared-types';
import {
  createIncreaseToInstruction,
  createDecreaseToInstruction,
  createDecreaseToInstructionForAccountGroups,
  createIncreaseToInstructionForAccountGroups,
} from '@app/services/instructions';
import { Modal } from '@app/components/Modal';

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const increasePositionTo = 'increase-position-to';
const decreasePositionTo = 'decrease-position-to';
const sellAll = 'sell-all';
const proRata = 'Pro Rata';
const equal = 'Equal';

function InstructionsForm({ gridRef }: Readonly<Props>) {
  const viewLevel = useAppSelector(
    (state: RootState) => state.accountGroups.viewLevel,
  );

  const selectedLevel =
    viewLevel === 'accountGroups' ? 'account group' : 'account';

  const originalProposals = useAppSelector(
    (state: RootState) => state.accountGroups.originalProposals,
  );

  const selectedInstruction = useRef<string>('');
  const inputtedPercentage = useRef<string>('');
  const selectedAllocation = useRef<string>('');
  const excludedServiceTypes = useRef<string[]>([]);
  const previousSelectedInstruction = useRef<string>('');
  const previousInputtedPercentage = useRef<string>('');
  const previousSelectedAllocation = useRef<string>('');
  const previousExcludedServiceTypes = useRef<string[]>([]);
  const [showUnitPrice, setShowUnitPrice] = React.useState<boolean>(false);
  const [showPercentage, setShowPercentage] = React.useState<boolean>(false);
  const [showAllocationType, setShowAllocationType] =
    React.useState<boolean>(false);
  const [showExcludeServiceType, setShowExcludeServiceType] =
    React.useState<boolean>(false);
  const [isLoaderVisible, setIsLoaderVisible] = React.useState<boolean>(false);
  const applyInstructionModalId = 'modal-applyInstruction';
  const applyInstructionModalButtons = [
    <ButtonSecondaryWithOnClick
      key="Cancel"
      buttonText="Cancel"
      dataTestId="apply-new-btn-cancel"
      onClick={() => showModal(applyInstructionModalId, false)}
    />,
    <ButtonSubmit
      key="apply"
      buttonText="Apply new"
      dataTestId="apply-new-btn-apply-new"
      mode={ButtonMode.Primary}
      isLoaderVisible={false}
      raiseEvent={() => {
        handleOnSubmit();
        showModal(applyInstructionModalId, false);
        previousSelectedInstruction.current = selectedInstruction.current;
        previousInputtedPercentage.current = inputtedPercentage.current;
        previousSelectedAllocation.current = selectedAllocation.current;
        previousExcludedServiceTypes.current = excludedServiceTypes.current;
      }}
    />,
  ];
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      previousSelectedInstruction.current = selectedInstruction.current;
      previousInputtedPercentage.current = inputtedPercentage.current;
      previousSelectedAllocation.current = selectedAllocation.current;
      previousExcludedServiceTypes.current = excludedServiceTypes.current;
      selectedInstruction.current = event.target.value;
      switch (selectedInstruction.current) {
        case sellAll: {
          setShowPercentage(false);
          setShowUnitPrice(true);
          setShowAllocationType(false);
          setShowExcludeServiceType(false);
          break;
        }
        case increasePositionTo: {
          setShowPercentage(true);
          setShowUnitPrice(true);
          if (viewLevel === 'accountGroups') {
            setShowAllocationType(true);
            setShowExcludeServiceType(true);
          } else {
            setShowAllocationType(false);
            setShowExcludeServiceType(false);
          }
          break;
        }
        case decreasePositionTo: {
          setShowPercentage(true);
          setShowUnitPrice(true);
          setShowAllocationType(false);
          setShowExcludeServiceType(false);
          break;
        }
        default: {
          setShowUnitPrice(false);
          setShowPercentage(false);
          setShowAllocationType(false);
          setShowExcludeServiceType(false);
        }
      }
    },
    [
      setShowUnitPrice,
      setShowPercentage,
      setShowAllocationType,
      setShowExcludeServiceType,
      viewLevel,
    ],
  );

  const handleOnChange = (event: InputKeyboardEvent) => {
    inputtedPercentage.current = event.target.value;
  };

  const handleAllocationTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      selectedAllocation.current = event.target.value;
      //Will need to pass on the selection value to the calculation in another ticket
      switch (selectedAllocation.current) {
        case proRata: {
          return 'Pro Rata';
        }
        case equal: {
          return 'Equal';
        }
        default: {
          return;
        }
      }
    },
    [selectedAllocation],
  );

  const handleOnSelect = useCallback((event: FilterSelectEventDetail) => {
    excludedServiceTypes.current = (event.selected as MenuItemMeta[]).map(
      (item) => item.value,
    );
  }, []);

  const handleInstruction = useCallback(
    async (proposals: (Adjustment | AccountGroupAdjustment)[]) => {
      let updatedProposals;

      switch (selectedInstruction.current) {
        case sellAll: {
          const adjustment: SharedInstructionAdjustment = {
            type: InstructionAdjustmentType.DecreasePercentTo,
            value: '0',
          };
          updatedProposals = await (viewLevel === 'accountGroups'
            ? createDecreaseToInstructionForAccountGroups(
                {
                  type: InstructionAdjustmentType.DecreaseVolumeTo,
                  value: '0',
                  allocationType: AllocationType.ProRataDecreaseTo,
                } as SharedInstructionAccountGroupAdjustment,
                proposals as AccountGroupAdjustment[],
              )
            : createDecreaseToInstruction(
                adjustment,
                proposals as Adjustment[],
              ));
          return updatedProposals;
        }
        case increasePositionTo: {
          updatedProposals = await (viewLevel === 'accountGroups'
            ? createIncreaseToInstructionForAccountGroups(
                {
                  type: InstructionAdjustmentType.IncreaseVolumeTo,
                  value: inputtedPercentage.current,
                  allocationType:
                    selectedAllocation.current === 'pro-rata'
                      ? AllocationType.ProRataIncreaseTo
                      : AllocationType.EquallyIncreaseTo,
                  excludeServiceTypes: excludedServiceTypes.current,
                } as SharedInstructionAccountGroupAdjustment,
                proposals as AccountGroupAdjustment[],
              )
            : createIncreaseToInstruction(
                {
                  type: InstructionAdjustmentType.IncreasePercentTo,
                  value: inputtedPercentage.current,
                } as SharedInstructionAdjustment,
                proposals as Adjustment[],
              ));
          return updatedProposals;
        }
        case decreasePositionTo: {
          updatedProposals = await (viewLevel === 'accountGroups'
            ? createDecreaseToInstructionForAccountGroups(
                {
                  type: InstructionAdjustmentType.DecreaseVolumeTo,
                  value: inputtedPercentage.current,
                  allocationType: AllocationType.ProRataDecreaseTo,
                } as SharedInstructionAccountGroupAdjustment,
                proposals as AccountGroupAdjustment[],
              )
            : createDecreaseToInstruction(
                {
                  type: InstructionAdjustmentType.DecreasePercentTo,
                  value: inputtedPercentage.current,
                } as SharedInstructionAdjustment,
                proposals as Adjustment[],
              ));
          return updatedProposals;
        }
        default: {
          return;
        } // or handle default case
      }
    },
    [viewLevel, inputtedPercentage, selectedInstruction],
  );

  const handleExcludedServiceTypes = useCallback(() => {
    gridRef.current?.api.forEachNode((node) => {
      if (
        node.data &&
        (node.data as Adjustment).accountId !== '' &&
        (node.data as Adjustment).serviceType !== '' &&
        excludedServiceTypes.current.includes(
          (node.data as Adjustment).serviceType,
        )
      ) {
        node.setSelected(false);
      } else {
        node.setSelected(true);
      }
    });
  }, [gridRef, excludedServiceTypes]);

  const shouldShowModal = () => {
    return (
      (selectedInstruction.current !== previousSelectedInstruction.current &&
        previousSelectedInstruction.current !== '') ||
      (inputtedPercentage.current !== previousInputtedPercentage.current &&
        previousInputtedPercentage.current !== '') ||
      (selectedAllocation.current !== previousSelectedAllocation.current &&
        previousSelectedAllocation.current !== '') ||
      excludedServiceTypes.current !== previousExcludedServiceTypes.current
    );
  };

  const handleSubmitAsync = useCallback(async () => {
    if (gridRef.current && selectedInstruction.current !== '') {
      const proposals: (Adjustment | AccountGroupAdjustment)[] = [];
      originalProposals.forEach((node) => {
        const adjustment = node;
        proposals.push(adjustment);
      });

      // Show modal if instruction changed
      if (shouldShowModal()) {
        showModal('modal-applyInstruction', true);
        return;
      }

      setIsLoaderVisible(true);
      const updatedProposals = await handleInstruction(proposals);

      if (viewLevel === 'accountGroups') {
        gridRef.current.api.setGridOption(
          'rowData',
          (updatedProposals?.proposals as AccountGroupAdjustment[]).flatMap(
            (proposal) => [proposal.accountGroup, ...proposal.accounts],
          ),
        );

        gridRef.current?.api.forEachNode((node) => {
          node.setSelected(true);
        });

        if (selectedInstruction.current === increasePositionTo) {
          handleExcludedServiceTypes();
        }
      } else {
        gridRef.current.api.setGridOption(
          'rowData',
          updatedProposals?.proposals,
        );
      }
    }
    setIsLoaderVisible(false);
  }, [
    gridRef,
    originalProposals,
    handleInstruction,
    viewLevel,
    handleExcludedServiceTypes,
  ]);

  const handleOnSubmit = useCallback(() => {
    handleSubmitAsync().catch((error) => {
      console.error('Error:', error);
    });
  }, [handleSubmitAsync]);

  return (
    <IressForm
      data-testid="adjustments-form"
      disabledClearOnSubmit
      hiddenErrorSummary
      onSubmit={handleOnSubmit}
    >
      <IressRow
        gutter={IressRow.Gutter.Lg}
        verticalAlign={IressRow.VerticalAlign.Bottom}
      >
        <IressCol span={IressCol.Span.Three}>
          <SelectInstruction
            handleChange={handleChange}
            selectedLevel={selectedLevel}
          />
        </IressCol>
        <IressCol>
          <IressInline
            gutter={IressInline.Gutter.Lg}
            verticalAlign={IressInline.VerticalAlign.Bottom}
          >
            {showPercentage && (
              <InputPercentage handleChange={handleOnChange} />
            )}
            {showUnitPrice && <InputUnitPrice />}

            {showAllocationType && (
              <SelectAllocationType handleChange={handleAllocationTypeChange} />
            )}

            {showExcludeServiceType && (
              <SelectExcludeServiceType onSelect={handleOnSelect} />
            )}

            <ButtonSubmit
              key="apply"
              buttonText="Apply"
              dataTestId="submit-btn"
              mode={ButtonMode.Primary}
              isLoaderVisible={isLoaderVisible}
            />
          </IressInline>
        </IressCol>
      </IressRow>
      <Modal
        id={applyInstructionModalId}
        dataTestId={applyInstructionModalId}
        title="Apply new instruction?"
        message="If you apply a new instruction, your current adjustments will not be saved."
        buttons={applyInstructionModalButtons}
      />
    </IressForm>
  );
}
export default InstructionsForm;
